import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import ReactGA from 'react-ga4';

import AppNotify from '../AppNotify/AppNotify';
import UserMenu from '../UserMenu/UserMenu';
import FaqView from '../../views/Faq/Faq';

import { getDailyTrends, getDistrictData, getGroups, getMP, getTrends } from '../../../http/dataAPI';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { getCustomState } from '../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../util/constants/AppConstants';

import UserIcon from '../../../assets/images/icons/user.svg';
import ArrowIconWhite from '../../../assets/images/icons/arrow-right-white.svg';
import './Header.scss';

const Header = () => {
  const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
  const [notifyNum, setNotifyNum] = useState(0);
  const [faqPage, setFaqPage] = useState(false);
  const [isSearchesDropdownOpened, setIsSearchesDropdownOpened] = useState(false);
  const [isElectionsDropdownOpened, setIsElectionsDropdownOpened] = useState(false);
  const [groups, setGroups] = useState([{ id: 0, name: 'Searches' }]);
  const [selectedGroupId, setSelectedGroupId] = useState(0);
  const [district, setDistrict] = useState(null);
  const [marketpulse, setMarketpulse] = useState(false);
  const [trends, setTrends] = useState(null);
  const [dailyTrends, setDailyTrends] = useState(null);
  const [trendsDates, setTrendsDates] = useState(null);

  const [userDataState, setUserDataState] = useState(false);
  const [userInfo, setUserInfo] = useState(null);

  const [dataFetching, setDataFetching] = useState(false);

  const config = useSelector((state) => state.config.config);
  const actions = useSelector((state) => state.action);
  const session = getCustomState(SESSION_STATE);

  const searchesDropdownRef = useRef();
  const searchesDropdownLabelRef = useRef();
  const electionsDropdownRef = useRef();
  const electionsDropdownLabelRef = useRef();
  const profileRef = useRef();
  const profileIcon = useRef();
  const scrollbarRef = useRef();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isSearches = pathname === '/';
  const isElections = pathname.startsWith('/election');

  const handleGetGroups = async (userId) => {
    const res = await getGroups(config, userId);

    if (res.status === 200) {
      setGroups([{ id: 0, name: 'Searches' }, ...res.data.groups]);
    } else {
      console.log('ERROR: Grabbing Groups');
    }
  };

  const handleGetDistrict = async (userId) => {
    const res = await getDistrictData(config, userId);

    if (res.status === 200) {
      setDistrict(res.data.district);
    } else {
      console.log('ERROR: Grabbing District');
    }
  };

  const handleGetMP = async (userId) => {
    const res = await getMP(config, userId);

    if (res.status === 200) {
      setMarketpulse(Boolean(res.data.mp_enabled));
    } else {
      console.log('ERROR: Grabbing MarketPulse info');
    }
  };

  const handleGetTrends = async (userId) => {
    const res = await getTrends(config, userId);

    if (res.status === 200) {
      setTrends(res.data.trends);
    } else {
      console.log('ERROR: Grabbing Trends');
    }
  };

  const handleGetDailyTrends = async () => {
    const res = await getDailyTrends(config);

    if (res.status === 200) {
      setDailyTrends(res.data.dailyTrends);
      setTrendsDates(res.data.dates);
    } else {
      console.log('ERROR: Grabbing Daily Trends');
    }
  };

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    if (config.api && session && !dataFetching) {
      const userData = getCustomState(USER_DATA, true);
      setUserInfo(userData);
      handleGetGroups(userData.id);
      handleGetDistrict(userData.id);
      handleGetMP(userData.id);
      handleGetTrends(userData.id);
      handleGetDailyTrends();
      setDataFetching(true);
    }
  }, [config, session, userDataState, dataFetching]);

  useEffect(() => {
    setUserDataState(getCustomState(USER_DATA, true));
  }, []);

  useEffect(() => {
    if (!userInfo && userDataState && config.api && !actions.loggingIn) {
      setUserInfo(userDataState);
    }
  }, [userDataState, userInfo, config, actions]);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpened((prevState) => !prevState);
  };

  const handleFaqPage = () => {
    setFaqPage((prevState) => !prevState);
    setIsUserMenuOpened(false);
  };

  const handleTabClick = (tabName) => {
    ReactGA.event({
      category: 'top_nav',
      action: 'click',
      label: `User ${userInfo.firstname} ${userInfo.lastname} viewed ${tabName} tab`,
    });
  };

  const handleSearchesClick = (e) => {
    if (isSearches && groups.length > 1) {
      e.preventDefault();
      setIsSearchesDropdownOpened((prev) => !prev);
    }
    handleTabClick('Searches');
  };

  const handleElectionsClick = (e) => {
    if (district.elections.length > 1) {
      e.preventDefault();
      setIsElectionsDropdownOpened((prev) => !prev);
    }
    handleTabClick('Elections');
  };

  const handleGroupClick = (groupId) => {
    setSelectedGroupId(groupId);
    setIsSearchesDropdownOpened(false);
  };

  const handleElectionClick = (electionId) => {
    setIsElectionsDropdownOpened(false);
    navigate(`/election/${electionId}`);
  };

  useOutsideClick(searchesDropdownRef, searchesDropdownLabelRef, isSearchesDropdownOpened, () =>
    setIsSearchesDropdownOpened(false),
  );
  useOutsideClick(electionsDropdownRef, electionsDropdownLabelRef, isElectionsDropdownOpened, () =>
    setIsElectionsDropdownOpened(false),
  );
  useOutsideClick(profileRef, profileIcon, isUserMenuOpened, () => setIsUserMenuOpened(false));

  return (
    <>
      <AppNotify />
      <div className="navigation">
        <nav className="navigation__menu">
          <ul className="navigation__links">
            <li>
              <NavLink ref={searchesDropdownLabelRef} className="navigation__link" to="/" onClick={handleSearchesClick}>
                {groups.find((group) => group.id === selectedGroupId).name}
                {isSearches && groups.length > 1 && (
                  <img
                    className={`navigation__arrow ${isSearchesDropdownOpened ? 'rotated' : ''}`}
                    src={ArrowIconWhite}
                  />
                )}
              </NavLink>
              {isSearchesDropdownOpened && (
                <div
                  className={`options-wrapper ${groups.length === 2 ? 'm' : groups.length === 3 ? 'l' : ''}`}
                  ref={searchesDropdownRef}
                >
                  <PerfectScrollbar
                    options={{
                      wheelPropagation: false,
                      autoHide: false,
                    }}
                  >
                    <ul className="options-list">
                      {groups.map((group) => (
                        <li key={group.id} onClick={() => handleGroupClick(group.id)}>
                          {group.name}
                        </li>
                      ))}
                    </ul>
                  </PerfectScrollbar>
                </div>
              )}
            </li>
            {district && (
              <li>
                <NavLink
                  ref={electionsDropdownLabelRef}
                  className={`navigation__link ${isElections ? 'active' : ''}`}
                  to={`/election/${district.elections[0].id}`}
                  onClick={handleElectionsClick}
                >
                  Elections
                  {district.elections.length > 1 && (
                    <img
                      className={`navigation__arrow ${isElectionsDropdownOpened ? 'rotated' : ''}`}
                      src={ArrowIconWhite}
                    />
                  )}
                </NavLink>
                {isElectionsDropdownOpened && (
                  <div
                    className={`options-wrapper ${district.elections.length === 2 ? 'm' : district.elections.length === 3 ? 'l' : ''} ${isElections ? '' : 'election'}`}
                    ref={electionsDropdownRef}
                  >
                    <PerfectScrollbar
                      options={{
                        wheelPropagation: false,
                        autoHide: false,
                      }}
                    >
                      <ul className="options-list">
                        {district.elections
                          .sort((a, b) => a.id - b.id)
                          .map((election) => (
                            <li key={election.id} onClick={() => handleElectionClick(election.id)}>
                              {election.election}
                            </li>
                          ))}
                      </ul>
                    </PerfectScrollbar>
                  </div>
                )}
              </li>
            )}
            {district &&
              (district.atlasMapPdfKey || district.detailedMapPdfKey || district.district.startsWith('Brampton')) && (
                <li>
                  <NavLink className="navigation__link" to="/maps" onClick={() => handleTabClick('Maps')}>
                    Maps
                  </NavLink>
                </li>
              )}
            {marketpulse && (
              <li>
                <NavLink className="navigation__link" to="/research" onClick={() => handleTabClick('Research')}>
                  Research
                </NavLink>
              </li>
            )}
            <li>
              <NavLink className="navigation__link" to="/trends" onClick={() => handleTabClick('Trends')}>
                Trends
              </NavLink>
            </li>
          </ul>
        </nav>
        <div ref={profileIcon} className={`user-icon ${isUserMenuOpened ? 'opened' : ''}`} onClick={handleOpenUserMenu}>
          {notifyNum > 0 ? <div className="notify-num">{notifyNum}</div> : ''}
          {userInfo ? Array.from(userInfo.firstname)[0] + Array.from(userInfo.lastname)[0] : <img src={UserIcon} />}
        </div>
        {isUserMenuOpened && (
          <UserMenu
            profileRef={profileRef}
            setIsOpen={setIsUserMenuOpened}
            userInfo={userInfo}
            handleFaqPage={handleFaqPage}
          />
        )}
      </div>
      <div className="outlet-wrap">
        <PerfectScrollbar
          ref={scrollbarRef}
          options={{
            wheelPropagation: true,
            autoHide: false,
          }}
        >
          <Outlet
            context={{ setFaqPage, userDataState, selectedGroupId, district, trends, dailyTrends, trendsDates }}
          />
        </PerfectScrollbar>
      </div>
      {faqPage && <FaqView isOpen={faqPage} handleFaqPage={handleFaqPage} />}
      <div className="background-elem"></div>
    </>
  );
};

export default Header;
