import React, { useState, useEffect } from 'react';

import ArrowIcon from '../../../../assets/images/icons/arrow-right.svg';
import ArrowIconWhite from '../../../../assets/images/icons/arrow-right-white.svg';
import './TopicItem.scss';

const TopicItem = ({
  data,
  icon = false,
  iconHover = false,
  topicHandler,
  activeTopic = false,
  topicIcon,
  topicIconWhite,
  isActive = false,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [iconDisplay, setIconDisplay] = useState(topicIcon);
  const [arrowDisplay, setArrowDisplay] = useState(ArrowIcon);
  const [activeTopicStatus, setActiveTopicStatus] = useState(false);

  const setTopicHandler = () => {
    topicHandler(data.name, data.id);
  };

  const handleHover = () => {
    setIsHover(true);
    setIconDisplay(topicIconWhite);
    setArrowDisplay(ArrowIconWhite);
  };

  const handleExit = () => {
    setIsHover(false);
    setIconDisplay(topicIcon);
    setArrowDisplay(ArrowIcon);
  };

  useEffect(() => {
    if (icon && !isHover) {
      setIconDisplay(icon);
    }
    if (iconHover && isHover) {
      setIconDisplay(iconHover);
    }
    if ((activeTopic && activeTopic === data.id) || isActive) {
      setActiveTopicStatus(true);
      return;
    }
    setActiveTopicStatus(false);
  });

  return (
    <div
      className={`menu-topic ${activeTopicStatus ? 'active' : ''} ${data.name === 'Daily trends' ? 'custom' : ''}`}
      onClick={setTopicHandler}
      onMouseEnter={handleHover}
      onMouseLeave={handleExit}
    >
      <div className="menu-topic-icon">
        <img src={activeTopicStatus ? topicIconWhite : iconDisplay} />
      </div>
      <div className="menu-topic-label">{data.name}</div>
      <div className="menu-topic-go">
        <img src={arrowDisplay} />
      </div>
    </div>
  );
};

export default TopicItem;
