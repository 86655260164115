import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';

import TrendsList from '../../components/TrendsList/TrendsList';
import DateDropdown from '../../components/DateDropdown/DateDropdown';
import InterestOverTime from '../../components/Sections/InterestOverTime/InterestOverTime';
import InterestByRegion from '../../components/Sections/InterestByRegion/InterestByRegion';
import DailyTrends from '../../components/Sections/DailyTrends/DailyTrends';

import { getDailyTrends, getTrendByDate } from '../../../http/dataAPI';

import './Trends.scss';

const Trends = () => {
  const config = useSelector((state) => state.config.config);

  const { userDataState, trends, dailyTrends, trendsDates } = useOutletContext();

  const [title, setTitle] = useState('Daily trends');
  const [activeTopicId, setActiveTopicId] = useState(null);
  const [activeTopic, setActiveTopic] = useState(null);
  const [activeDate, setActiveDate] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dailyTopics, setDailyTopics] = useState(null);
  const [dailyTrendsLoading, setDailyTrendsLoading] = useState(true);
  const [showDailyTrends, setShowDailyTrends] = useState(true);

  const menuRef = useRef();
  const menuCloseIcon = useRef();

  const toggleMobileMenu = () => {
    setMobileMenuOpen((prevState) => !prevState);
  };

  const setLastDate = () => {
    setActiveDate(trendsDates.sort((a, b) => b - a)[0]);
  };

  useEffect(() => {
    if (trends) {
      setLoading(false);
    }
  }, [trends]);

  useEffect(() => {
    if (dailyTrends) {
      setDailyTopics(dailyTrends);
      setDailyTrendsLoading(false);
    }
  }, [dailyTrends]);

  useEffect(() => {
    if (trendsDates) {
      setLastDate();
    }
  }, [trendsDates]);

  useEffect(() => {
    if (activeTopicId && trends && trendsDates) {
      const topic = trends.find((trend) => trend.id === activeTopicId);
      setActiveTopic(topic);
      setLastDate();
    }
  }, [activeTopicId]);

  useEffect(() => {
    if (dailyTrends && trendsDates) {
      setLastDate();
      setDailyTopics(dailyTrends);
    }
  }, [showDailyTrends]);

  const handleDateChange = async (date) => {
    if (showDailyTrends) {
      setDailyTrendsLoading(true);
      const res = await getDailyTrends(config, date);

      if (res.status === 200) {
        setDailyTopics(res.data.dailyTrends);
        setDailyTrendsLoading(false);
      } else {
        console.log('ERROR: Grabbing Daily Trends by Date');
      }
    } else {
      setLoading(true);
      const res = await getTrendByDate(config, userDataState.id, activeTopicId, date);

      if (res.status === 200) {
        setActiveTopic(res.data);
        setLoading(false);
      } else {
        console.log('ERROR: Grabbing Trend by Date');
      }
    }
  };

  return (
    <div className="platform-frame">
      <div ref={menuCloseIcon} className="dm-left mobile-menu">
        <span onClick={toggleMobileMenu} className={mobileMenuOpen ? 'open-menu' : ''}>
          <div></div>
          <div></div>
          <div></div>
        </span>
      </div>
      <TrendsList
        isOpen={mobileMenuOpen}
        setIsOpen={setMobileMenuOpen}
        menuRef={menuRef}
        setTitle={setTitle}
        activeTopicId={activeTopicId}
        setActiveTopicId={setActiveTopicId}
        userDataState={userDataState}
        showDailyTrends={showDailyTrends}
        setShowDailyTrends={setShowDailyTrends}
      />
      <div className="pf-right trends">
        <div className="pfr-header">
          <div className="dashboard-title">
            {title}
            {trendsDates && (
              <DateDropdown
                type="unix-date"
                data={trendsDates}
                activeItem={activeDate}
                setActiveItem={setActiveDate}
                onItemChange={(date) => handleDateChange(date)}
              />
            )}
          </div>
        </div>
        <div className="dashboard-components">
          <div className="dc-left">
            {showDailyTrends && (
              <div className="dashboard-line">
                <DailyTrends trends={dailyTopics} loading={dailyTrendsLoading} />
              </div>
            )}
            {!showDailyTrends && (
              <div className="dashboard-line">
                <InterestOverTime activeTopic={activeTopic} loading={loading} />
              </div>
            )}
            {!showDailyTrends && (
              <div className="dashboard-line">
                <InterestByRegion activeTopic={activeTopic} loading={loading} />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends;
