import React, { useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import useOutsideClick from '../../../hooks/useOutsideClick';
import { formatDate, formatUnixDate } from '../../../util/HelperFunctions';

import ArrowIcon from '../../../assets/images/icons/arrow-right-blue.svg';
import './DateDropdown.scss';

const DateDropdown = ({ type, data, activeItem, setActiveItem, onItemChange }) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);

  const dropdownRef = useRef();
  const dropdownLabelRef = useRef();

  const handleDropDownClick = () => {
    if (data.length > 1) {
      setIsDropdownOpened((prev) => !prev);
    }
  };

  const handleItemClick = (item) => {
    setActiveItem(item);
    setIsDropdownOpened(false);

    if (type === 'unix-date') {
      onItemChange(item);
    }
  };

  useOutsideClick(dropdownRef, dropdownLabelRef, isDropdownOpened, () => setIsDropdownOpened(false));

  return (
    <div className={`dropdown ${data.length > 1 ? 'pointer' : ''}`}>
      {activeItem && (
        <div className="dropdown__active" ref={dropdownLabelRef} onClick={handleDropDownClick}>
          {type === 'summary-date' ? formatDate(activeItem.date) : formatUnixDate(activeItem)}
          {data.length > 1 && (
            <img className={`navigation__arrow ${isDropdownOpened ? 'rotated' : ''}`} src={ArrowIcon} />
          )}
        </div>
      )}
      {isDropdownOpened && (
        <div
          className={`options-wrapper ${data.length > 1 && data.length < 7 ? `n${data.length}` : ''}`}
          ref={dropdownRef}
        >
          <PerfectScrollbar
            options={{
              wheelPropagation: false,
              autoHide: false,
            }}
          >
            <ul className="options-list">
              {data
                .sort((a, b) => b - a)
                .map((item) => (
                  <li
                    key={type === 'summary-date' ? item.id : item}
                    className={
                      (type === 'summary-date' && item.id === activeItem.id) ||
                      (type !== 'summary-date' && activeItem === item)
                        ? 'active'
                        : ''
                    }
                    onClick={() => handleItemClick(item)}
                  >
                    {type === 'summary-date' ? formatDate(item.date) : formatUnixDate(item)}
                  </li>
                ))}
            </ul>
          </PerfectScrollbar>
        </div>
      )}
    </div>
  );
};

export default DateDropdown;
