import React from 'react';

import DataGridComponent from '../../Forms/DataGrid/DataGrid';
import Fader from '../../Loaders/Fader';

const DailyTrends = ({ trends, loading }) => {
  return (
    <div className="interest chart-component">
      <div className="sentiment-charts">
        {!loading ? (
          trends.length ? (
            trends
              .sort((a, b) => b.trafficValue - a.trafficValue)
              .map((item, index) => (
                <DataGridComponent
                  key={index}
                  dataNumber={index + 1}
                  data={item}
                  type={'daily-trend'}
                  truncateText={false}
                />
              ))
          ) : (
            <div className="no-data">No Data Available. Please Check Back Shortly.</div>
          )
        ) : (
          <Fader />
        )}
      </div>
    </div>
  );
};

export default DailyTrends;
