import { units } from './constants/AppConstants';

export const createHash = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const truncate = (str, num) => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const memo = (callback) => {
  const cache = new Map();
  return (...args) => {
    const selector = JSON.stringify(args);
    if (cache.has(selector)) return cache.get(selector);
    const value = callback(...args);
    cache.set(selector, value);
    return value;
  };
};

export const formatNumber = (number) => {
  return Number(number).toFixed(2);
};

export const formatNumberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const abbreviateNumber = (numberStr) => {
  if (typeof numberStr !== 'string' || !numberStr || numberStr.trim() === '') {
    return 0;
  }

  const number = parseFloat(numberStr.replace(/,/g, ''));

  if (number < 1e6) return numberStr;

  const order = Math.floor(Math.log10(number) / 3);
  const unitname = units[order];
  const num = Number((number / 10 ** (order * 3)).toFixed(1));

  return `${num}${unitname}`;
};

export const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};

export const formatUnixDate = (unixDate) => {
  const date = new Date(unixDate * 1000);
  const formatter = new Intl.DateTimeFormat('en-US', {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC',
  });
  return formatter.format(date);
};
